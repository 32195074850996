<template>
  <div class="h-100">
    <b-carousel
      id="carousel-example-generic"
      controls
      indicators
    >
      <b-carousel-slide img-src="http://haomengu.webshopping.vip/assets/images/banner1.jpg" />
    </b-carousel>

    <b-container>
      <b-row class="h-100 justify-content-center mt-2">
        <b-col
          v-for="i in 10"
          :key="i"
          cols="12"
          md="3"
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-link :to="{ name: 'apps-e-commerce-product-details' }">
                <b-img
                  fluid
                  src="http://haomengu.webshopping.vip/assets/Product/p01.png"
                />
              </b-link>

              <b-card-body>
                <lable
                  class="sub-title"
                  data-toggle="modal"
                  data-target="#goods4"
                >
                  豪門亦如是，豪門騎士團參上！
                </lable>

                <div class="group mb-2 itemContents">
                  <p class="title">
                    最強職業系禮包
                  </p>
                  <p class="NTD">
                    NT$
                    <span class="pricez">
                      5000
                    </span>
                  </p>
                </div>

                <div class="input-group mb-3">
                  <input
                    type="number"
                    class="form-control qty"
                    min="0"
                    value="0"
                  >
                  <button
                    class="btn btn-outline-dark btnAddCart"
                    type="button"
                  >
                    <i class="bi bi-cart" />
                    加入購物車
                  </button>
                </div>

              </b-card-body>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-table-simple responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>商品</b-th>
            <b-th>品名</b-th>
            <b-th>數量</b-th>
            <b-th>小計</b-th>
            <b-th>移除</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>金條X9999</b-td>
            <b-td>金條X9999</b-td>
            <b-td>2</b-td>
            <b-td>200000</b-td>
            <b-td>1</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td
              colspan="7"
              variant="secondary"
              class="text-right"
            >
              Total Rows: <b>5</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

      <div class="py-4">

        <div class="bg-light-secondary height-100 d-flex align-items-center justify-content-center">
          <div class="pb-4">

            <div>
              <span class="h3 text-info">
                當前方案
              </span>
            </div>

            <small
              class="text-muted"
              style="right: 0; left: 0;"
            >

              <!-- <span v-show="isDiscount(subscription)">
                  TWD {{ defaultPrice(subscription).amount }} / {{ defaultPrice(subscription).unit }}
                  <span>{{ parsingDiscount(subscription) }}</span>
                </span> -->
            </small>
          </div>
        </div>
      </div>

    </b-container>

    <slot />
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
  BCard, BCardBody, BImg, BLink,
  BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BContainer,
    BRow,
    BCol,

    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,

    BCard,
    BCardBody,
    BLink,
    BImg,

    BCarousel,
    BCarouselSlide,
  },
  directives: {
    Ripple,
  },
  props: {
    showData: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const orderData = ref({
      money: 300,
      // pt: 'ecpay_cvs',
      pt: null,
    })
    /* eslint-disable global-require */
    const sideImg = computed(() => props.showData.bg_image || require('@/assets/images/background/particles_bg.jpg'))

    const pays = [
      { text: '超商代碼繳費(最高繳款金額6000)-請選擇此繳費方式', value: 'ecpay_cvs' },
      { text: 'ATM轉帳繳費(最高繳款金額20000)-請選擇此繳費方式', value: 'ecpay_atm' },
      { text: '線上刷卡服務(最高繳款金額20000)-請選擇此繳費方式', value: 'ecpay_credit' },
    ]

    return {
      orderData,

      sideImg,
      pays,
    }
  },
}
</script>

<style lang="scss">
.form-control {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}

.custom-select {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
